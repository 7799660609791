@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');

  .text-primary {
    color: mat.get-color-from-palette($primary-palette, 800);
  }

  .text-accent {
    color: mat.get-color-from-palette($accent-palette, 500);
  }

  .mat-list-item.selected > .mat-list-item-content > a {
    background-color: mat.get-color-from-palette($primary-palette, 800);
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
}

@mixin material-custom-theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
