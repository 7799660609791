/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0
}

.no-wrap {
  white-space: nowrap;
}

@import 'front/variable';
@import 'front/theme';

@import 'front/mixins/spacing';
@import 'front/mixins/color';
@import 'front/mixins/grid';
@import 'front/mixins/font';

@import 'front/component/global';
@import 'front/component/heading';

@import 'front/component/header';
@import 'front/component/sidebar';
@import 'front/component/spinner';
@import 'front/component/card';
@import 'front/component/dashboard';

@import 'front/responsive';

/* MODAL */
.cdk-overlay-backdrop {
  &.cdk-overlay-backdrop-showing {
    background: rgba(0, 0, 0, .32) !important;
    opacity: 1 !important;
  }
}

.cdk-drag.list-item {
  border-bottom: 1px solid #722980 !important;
}

.cdk-drop-list.list {
  border: 1px solid #722980 !important;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.text-align-center {
  text-align: center;
}

@media screen and (max-width: 1318px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch;
  }

  th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    padding: 8px 24px !important;
    white-space: nowrap; //no break line
  }
}

.box-custom {
  display: flex;
  align-items: center;

  &-link {
    margin: 4px 4px 0;
    display: inline-block;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-sm {
  @media (max-width: 959px) {
    flex-direction: column !important;
  }
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}

.gap-45 {
  gap: 45px;
}

.gap-xs-none {
  @media (max-width: 599px) {
    gap: 0;
  }
}

.mw-55 {
  max-width: 55%;
}


.full-width-table {
  width: 100%;
}

button.mat-raised-button {
  span.mat-ripple {
    background: none !important;
  }
}

.btn-lg {
  height: 70%;
  width: 170px;
  color: #722980 !important;
  border-radius: 10px !important;
  @media (max-width: 1444px) {
    height: 50px;
  }
}

.color-primary {
  color: #722980 !important;
}

.radius-20 {
  border-radius: 20px !important;
}

.mat-title {
  &.faq {
    font-size: 26px;

    strong {
      font-size: 29px;
    }
  }

  &.big {
    font-size: 26px;
  }
}

.justify-center {
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
}

.background-white {
  background-color: #fff;
}

.color-white {
  color: #fff !important;
}

.justify-between {
  justify-content: space-between !important;
}

.mat-dialog-container {
  padding: 0 !important;
  border-radius: 13px !important;
}

.transform-icon {
  transform: scale(0.8);
}

.w-100 {
  width: 100% !important;
}

.w-100-xs {
  @media (max-width: 599px) {
    width: 100% !important;
  }
}

.w-100-sm {
  @media (max-width: 959px) {
    width: 100%;
  }
}

.w-100-md {
  @media (max-width: 1280px) {
    width: 100%;
  }
}

.w-100-lg {
  @media (max-width: 1550px) {
    width: 100%;
  }
}


.max-w-md {
  @media (max-width: 1280px) {
    max-width: 100% !important;
  }
}

.max-w-lg {
  @media (max-width: 1550px) {
    max-width: 100% !important;
  }
}

.gap-10-md {
  @media (max-width: 1280px) {
    gap: 10px;
  }
}

.gap-15-md {
  @media (max-width: 1280px) {
    gap: 15px;
  }
}

.gap-15-sm {
  @media (max-width: 959px) {
    gap: 15px;
  }
}

.gap-30-md {
  @media (max-width: 1280px) {
    gap: 30px;
  }
}

.gap-30-sm {
  @media (max-width: 959px) {
    gap: 30px;
  }
}

.gap-30-lg {
  @media (max-width: 1550px) {
    gap: 30px;
  }
}

.tab-custom {
  .mat-ripple.mat-tab-label.mat-focus-indicator {
    background-color: #B6F230 !important;
    color: #722980 !important;

    width: 50%;
    height: 50px;
    font-size: 16px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    opacity: 1;

    &.mat-tab-label-active {
      background-color: #722980 !important;
      color: #B6F230 !important;
    }

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }
}

.mat-tab-label-active {
  height: 60px !important;
}

.mat-tab-labels {
  align-items: end;
}


.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: #722980 !important;
}

.flex-wrap {
  flex-wrap: wrap;
  display: flex;
}

.page_bg-secondary {
  @media (max-width: 962px) {
    padding: calc(5rem / 4) !important;
  }
}

.mat-dialog-container {
  @media (max-width: 962px) {
    max-height: 615px !important;
  }
}

.mat-dialog-title {
  background-color: #B6F230;
}

.show-divider-sm {
  display: none;
  @media (max-width: 959px) {
    display: block !important;
  }
}

.break-all {
  word-break: break-all;
}

.datepicker-extract{
  .mat-ripple{
    background: none !important;
  }
}

.paginator-nocolor{
  .mat-ripple{
    background: none !important;
  }
}

.signups{
  width: 105px;
  font-size: 13px;
}
