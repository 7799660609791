// Utilities : Paddings, Margins
$spaces: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20);
$sides: (top, right, bottom, left);

@each $space in $spaces {
  .m-#{$space} {
    margin: calc(#{$space}rem / 4) !important;
  }
  .my-#{$space} {
    margin-top: calc(#{$space}rem / 4) !important;
    margin-bottom: calc(#{$space}rem / 4) !important;
  }
  .mx-#{$space} {
    margin-left: calc(#{$space}rem / 4) !important;
    margin-right: calc(#{$space}rem / 4) !important;
  }
  .p-#{$space} {
    padding: calc(#{$space}rem / 4)  !important;
  }
  .py-#{$space} {
    padding-top: calc(#{$space}rem / 4) !important;
    padding-bottom: calc(#{$space}rem / 4) !important;
  }
  .px-#{$space} {
    padding-left: calc(#{$space}rem / 4) !important;
    padding-right: calc(#{$space}rem / 4) !important;
  }

  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: calc(#{$space}rem / 4) !important;
    }
    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: calc(#{$space}rem / 4) !important;
    }
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
